<template>
  <div id="finish">
    <div class="solution-top">
    <h1>Möglicherweise Sanierungsmaßnahmen benötigt</h1>
    <p>
      Ihren Angaben entnehmen wir, dass Ihr Haus für den effizienten Betrieb einer Wärmepumpe möglicherweise energetische Sanierungsmaßnahmen benötigt. Wir empfehlen Ihnen daher, den Weg zu Ihrer neuen Heizung mit unserer Energieberatung fortzusetzen.
    </p>
      <!--
      <div class="alert">
        <h2>Aktueller Hinweis</h2>
        <p>Aufgrund der sofortigen Haushaltsperre erfolgen derzeit keine Bewilligungen von neuen Vorhaben durch das Bundesamt für Wirtschaft und Ausfuhrkontrolle (BAFA), die mit Zahlungen für die Jahre ab 2024 verbunden sind. Dies betrifft u.a. die Förderprogramme zur Energieberatung (EBN und EBW). Wir können Ihnen dieses Produkt daher leider aktuell nicht anbieten.</p>
        <p>Sobald die Aussetzung der Förderung beendet ist, werden wir unser Angebot zum Energieberatungspaket wieder aufnehmen. Schauen Sie gern zu einem späteren Zeitpunkt wieder bei uns vorbei.</p>

      </div>
      -->
      <img class="desktop" src="../assets/VAT_Energieberatung_transp.png">
    </div>
  <div class="solution bg-1">
    <div class="solution-box">
      <h1>Energieberatung</h1>
      <div class="bottom">
        <div class="calculation">
      <p>&ensp;<span class="bold">1.640 €</span> Gesamtpreis Energieberatung inkl. Steuern</p>
          <p><span class="bold">- 650 €</span> BAFA-Förderung iSFP (50% max. 650 €)*</p>
          <p class="result"><span class="bold">990 €</span> Ihr Eigenanteil inkl. Steuern **</p>
        </div>

      </div>
    </div>
    <div class="solution-box">
    <ul>
      <li>Ganzheitliche Vor-Ort-Begehung und Beratung inkl. Dokumentation des energetischen Ist-Zustands Ihres Hauses</li>
      <li>Erstellung eines Energieausweises (Bedarfsausweis) für Ihr Haus</li>
      <li>Entwicklung von Sanierungsvorschlägen, u. a. bzgl. der Gebäudehülle (Wände, Dach, Fenster, Böden) und Anlagetechnik (Heizung, Warmwasser, Wärmeverteilung, Lüftung) in Form eines individuellen Sanierungsfahrplans (iSFP)</li>
      <li>Auf Wunsch Festlegung und Abstimmung der individuellen Sanierungsschritte und des Sanierungsziels mit Ihnen als Hauseigentümer:in</li>
    </ul>
    <p>Die Durchführung erfolgt durch <span class="important">unabhängige und vom BAFA zugelassene Energieberater:innen</span> unserer Fachpartner:innen.
      </p>
    </div>
  </div>
    <div class="bg-1">
    <button @click="convert" class="button button_yellow button_final">
      {{ store.link_energieberatung.text }}</button>
      <!--
      <button class="button button_final button_disabled">Aktuell nicht bestellbar</button>
      -->
    <!--<button class="button button_ghost" @click="moreinfo">{{ store.link_energieberatung_mehrerfahren.text}}</button>-->
      </div>
    <p class="footnote"> * Bei dem angegebenen Betrag handelt es sich um die für den Gesamtpreis aktuell höchstmögliche Fördersumme der <a href="https://www.bundesanzeiger.de/pub/publication/Kq8fMEOrmswuiTF1pDc/content/Kq8fMEOrmswuiTF1pDc/BAnz%20AT%2021.06.2023%20B1.pdf?inline" target="_blank">iSFP-Richtlinie</a>.</p>
    <p class="footnote" >** Der angezeigte Eigenanteil ergibt sich aus dem Gesamtpreis inkl. Steuern abzüglich BAFA-Förderung. Dazu müssen Sie das BAFA ermächtigen, den Zuschuss direkt an das Energieberatungsunternehmen zu zahlen. Voraussetzung für die Auszahlung der BAFA-Förderung ist die Einhaltung der Bedingungen der o.g. iSFP-Richtlinie, insbesondere, dass der Bauantrag/ die Bauanzeige für Ihr Wohngebäude länger als zehn Jahre zurückliegt und für das Wohngebäude in den letzten vier Jahren keine Förderung für einen iSFP beantragt wurde. Bedingung für die Leistungserbringung ist die Erteilung der Förderzusage. Wird die Förderung aus Gründen, die in Ihren Verantwortungsbereich fallen, zurückgenommen oder widerrufen oder nicht an das Energieberatungsunternehmen ausgezahlt, sind Sie verpflichtet, Vattenfall die Differenz zum Gesamtpreis für das Energieberatungspaket im Nachhinein nach Rechnungslegung zu bezahlen.</p>
  </div>


</template>

<script>
import {Interpreter} from "xstate";
import { store } from '../store.js'

export default {
  name: 'StateFinishEnergieberatung',
  props: {
    clickTrackService: Interpreter,
  },
  data() {
    return {
      store,
      //energieberatungsbutton: "Energieberatung bestellen",
      //energieberatungslink: "https://online.vattenfall.de/energieberatungspaket",
      //mehrerfahrenbutton: "Mehr erfahren",
      //mehrerfahrenbuttonlink: "https://www.vattenfall.de/waerme/energieberatung",
    }
  },
  methods:{
    async convert(){
      await this.$root.storeData('conversion_click', 'finish_energieberatung', 'link');
      this.open();

    },
    async open(){
      window.open(this.store.link_energieberatung.url, "_parent");
    },

    async moreinfo(){
      await this.$root.storeData("mehr_erfahren", 'finish_energieberatung', 'link');
      window.open(this.store.link_energieberatung_mehrerfahren.url, "_parent");
    }

  },

}
</script>

