<template>
  <div class="formdata zipcode">
    <h2>In welcher PLZ steht Ihr Gebäude?</h2>
    <input type="text"
           placeholder="Ihre Postleitzahl"
           minlength="5"
           maxlength="5"
           class="zipcode input"
           name="zipcode"
           v-model="store.building.zip"
           v-on:keyup.enter="submit"
    />
    <button class="button button_yellow" v-if="zipcheck" @click="submit">Weiter</button>
      <p>Je nach Region und Gebäudezustand bieten wir Ihnen verschiedene Vor-Ort-Beratungen an, die in ausgewählten Gebieten in Deutschland verfügbar sind.<br/>
        In Süddeutschland arbeiten wir für die Erstellung Ihres Sanierungsfahrplans (iSFP) mit unserem Kooperationspartner 42watt (Enovato GmbH) zusammen. Die verfügbaren PLZ-Gebiete werden laufend weiter ausgebaut.</p>
    <div class="plz-karte">
      <img src="../assets/VAT_Deutschlandkarte.png" width="250"/>
    </div>




</div>



</template>

<script>
import {Interpreter} from "xstate";
import { store } from '../store.js'


export default {
  name: 'StateZipCode',
  props: {
    clickTrackService: Interpreter,
  },
  data() {
    return {
      store,
      message:
          "<p>Aktuell können wir in der von Ihnen angegebenen PLZ kein Angebot erstellen und daher auch keine Beratung durchführen.</p>" +
          "<p>Wir bauen unser Angebot aber schnellstmöglich weiter aus. " +
          "Schauen Sie gern in Kürze wieder vorbei.</p>",
      message_nrw: "<p>Für Ihr PLZ-Gebiet bieten wir aktuell unsere <strong>kostenlose Heizungs-Beratung</strong> an. Darin informieren unsere " +
          "Berater:innen Sie umfassend zu Eignung und Voraussetzungen einer Wärmepumpe, auf Wunsch aber auch über alternative Heizsysteme.",
      message_42watt: "<p>Wir können Ihnen in Ihrer Region die Energieberatung über unseren Partner 42Watt anbieten. <br /> " +
          "<strong>Alle Informationen finden Sie auf der Seite unseres Partners."
    }
  },
  computed: {
    zipcheck(){
     if  ( isNaN(this.store.building.zip) ) {
       return false;
     }
     if (this.store.building.zip <= 0){
       return false;
     }
     if (this.store.building.zip.length !== 5){
       return false;
     }
     return true;
    }
  },
  methods: {
    submit() {
      let success = false;
      if (this.store.zipCodes.includes(this.store.building.zip)){
        success = true
      }

      if(success) {
        if (this.store.plz_energie.includes(this.store.building.zip)){
          this.store.success_zip_energie = true;
        }
        if (this.store.plz_pumpe.includes(this.store.building.zip)){
          this.store.success_zip_pumpe = true;
        }
        this.store.message = "";
        this.clickTrackService.send('SUCCESS');
      }else{
        this.store.success_zip_energie = false;
        this.store.success_zip_pumpe = false;
        if (this.store.plz_extern.includes(this.store.building.zip)){
          this.store.success_zip_nrw = true;
          this.store.message = this.message_42watt;
          this.clickTrackService.send("EXTERN");
        }else if (this.store.plz_nrw.includes(this.store.building.zip)){
          this.store.success_zip_nrw = true;
          this.store.message = this.message_nrw;
          this.clickTrackService.send("NRW");
        }else {
          this.store.message = this.message;
          this.clickTrackService.send('FAILURE');
        }
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
